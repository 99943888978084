<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <v-row dense>
      <v-col cols="3">
        <scheduled-worship-form-card></scheduled-worship-form-card>
      </v-col>
      <v-col cols="9" class="pl-4">
        <worship-table></worship-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const ScheduledWorshipFormCard = () =>
  import("../cards/ScheduledWorshipFormCard.vue");
const WorshipTable = () => import("../tables/WorshipTable.vue");

export default {
  components: {
    ScheduledWorshipFormCard,
    WorshipTable
  }
};
</script>
